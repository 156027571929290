<template>
  <v-container class="pa-1">
    <v-row no-gutters class="price-fields">
      <v-col cols="12">
        <span class="subtitle" v-text="$t('common.price')" />
      </v-col>
      <v-col cols="12">
        <v-row no-gutters>
          <v-col>
            <p class="font-weight-bold price mb-1">
              {{ $t('discovery.min_price') }}
            </p>
            <v-text-field
              dense
              flat
              outlined
              placeholder="$"
              v-model.number="currentMinValue"
              v-on:keypress="onMinValueKeyPress($event)"
              @click="clearMinInput()"
              class="search-input"
            >
            </v-text-field>
          </v-col>
          <v-col class="d-flex align-center pa-3 pt-7">
            <span class="text-center justify-center">{{
              $t('to').toLowerCase()
            }}</span>
          </v-col>
          <v-col>
            <p class="mb-1 price font-weight-bold">
              {{ $t('discovery.max_price') }}
            </p>
            <v-text-field
              dense
              outlined
              placeholder="$"
              v-model.number="currentMaxValue"
              v-on:keypress="onMaxValueKeyPress($event)"
              @click="clearMaxInput()"
              class="search-input"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { currencyTextFormatter } from '@/core/filters/currency';

export default {
  name: 'PriceRangeMobile',
  props: {
    preSelectedPriceFilter: {
      type: Object,
      default: () => ({ min: '', max: '' }),
    },
  },

  data() {
    return {
      currentMinValue: '',
      currentMaxValue: '',
      submittedPrices: [{ min: '', max: '' }],
      currency: this.defaultCurrency,
    };
  },

  created() {
    this.setDefaultSelection();
  },

  methods: {
    clearMinInput() {
      this.currentMinValue = '';
      this.submittedPrices = [{ min: '', max: this.selectedPrices.max }];
    },

    clearMaxInput() {
      this.currentMaxValue = '';
      this.submittedPrices = [{ min: this.selectedPrices.min, max: '' }];
    },

    onMinValueKeyPress(evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;

      //Check that the key pressed is numerical
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        // Check that the key pressed is enter or return key
        if (charCode === 13) {
          this.validateValue();
        }
        return true;
      }
    },

    onMaxValueKeyPress(evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;

      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        if (charCode === 13) {
          this.onSave();
        }
        return true;
      }
    },

    validateValue() {
      if (this.currentMinValue === '' && this.currentMaxValue === '') {
        return true;
      } else {
        if (this.currentMinValue === this.currentMaxValue) {
          this.currentMinValue = this.currentMinValue - 1;
        }
        if (
          this.currentMaxValue !== '' &&
          this.currentMaxValue < this.currentMinValue
        ) {
          [this.currentMaxValue, this.currentMinValue] = [
            this.currentMinValue,
            this.currentMaxValue,
          ];
        }
        return true;
      }
    },

    formatPrice(price) {
      return this.$options.filters.currency(price, this.defaultCurrency);
    },

    setDefaultSelection() {
      let minPrice = this.preSelectedPriceFilter.min || '';
      let maxPrice = this.preSelectedPriceFilter.max || '';

      if (minPrice || maxPrice) {
        this.currentMinValue = minPrice;
        this.currentMaxValue = maxPrice;
        this.submittedPrices = [{ min: minPrice, max: maxPrice }];
        this.selectedPrices.min = minPrice;
        this.selectedPrices.max = maxPrice;

        this.displayedSelectedPrices();
      } else {
        this.submittedPrices = [{ min: '', max: '' }];
      }
    },

    displayedSelectedPrices() {
      let formattedPrice;
      let minFormattedPrice;
      let maxFormattedPrice;
      if (!this.hasSelectedPrices) {
        return this.$t('common.price');
      } else {
        if (
          this.submittedPrices[0].min !== '' &&
          this.submittedPrices[0].max === ''
        ) {
          formattedPrice = this.formatPrice(this.submittedPrices[0].min);
          return this.$t('common.from_price', { price: formattedPrice });
        } else if (
          this.submittedPrices[0].max !== '' &&
          this.submittedPrices[0].min === ''
        ) {
          formattedPrice = this.formatPrice(this.submittedPrices[0].max);
          return this.$t('common.to_price', { price: formattedPrice });
        } else {
          minFormattedPrice = this.formatPrice(this.submittedPrices[0].min);
          maxFormattedPrice = this.formatPrice(this.submittedPrices[0].max);
          return `${minFormattedPrice} - ${maxFormattedPrice}`;
        }
      }
    },

    clearValue() {
      (this.currentMinValue = ''),
        (this.currentMaxValue = ''),
        (this.submittedPrices = [{ min: '', max: '' }]);
    },
  },

  computed: {
    currencyText() {
      return currencyTextFormatter(this.defaultCurrency);
    },

    selectedPrices() {
      return {
        min: this.currentMinValue,
        max: this.currentMaxValue,
      };
    },

    hasSelectedPrices() {
      return (
        Boolean(this.submittedPrices[0].min !== '') ||
        Boolean(this.submittedPrices[0].max !== '')
      );
    },
  },

  watch: {
    selectedPrices: {
      deep: true,
      handler() {
        this.$emit('set-prices', this.selectedPrices);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.subtitle {
  display: block;
  padding-bottom: 1rem;
  font-weight: 600;
  font-size: 1rem;
}

::v-deep {
  .v-input__slot {
    border: 1px solid #d1d1d1;
    border-radius: 8px;
    width: 143px;
    min-height: 44px !important;

    & fieldset {
      border: none;
    }
  }

  .v-text-field__details {
    display: none;
  }
}

.price {
  color: #2f2f2f;
  font-size: 14px;
}

.price-fields .col {
  padding: 0;
}
</style>
