<template>
  <v-dialog v-model="show" :overlay-opacity="0" scrollable>
    <template v-slot:activator="{ on }">
      <v-btn
        small
        v-on="on"
        :class="['trigger-button', { active: addAppliedFilters() }]"
        height="32"
        outlined
      >
        <template>
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="3.54167"
              cy="4.25016"
              r="2.83333"
              stroke="#717171"
              stroke-width="1.41667"
            />
            <line
              x1="5.6665"
              y1="4.25016"
              x2="16.9998"
              y2="4.25016"
              stroke="#717171"
              stroke-width="1.41667"
            />
            <circle
              cx="13.4583"
              cy="12.7503"
              r="2.83333"
              transform="rotate(-180 13.4583 12.7503)"
              stroke="#717171"
              stroke-width="1.41667"
            />
            <line
              x1="11.3335"
              y1="12.7503"
              x2="0.000163016"
              y2="12.7503"
              stroke="#717171"
              stroke-width="1.41667"
            />
          </svg>
        </template>
        <span class="trigger">
          {{ triggerText }}
          {{ addAppliedFilters() ? `(${addAppliedFilters()})` : '' }}
        </span>
      </v-btn>
    </template>
    <v-sheet :elevation="0" color="white" class="fill-height">
      <v-container class="pa-5">
        <v-row no-gutters>
          <v-col cols="12">
            <span class="title" v-text="$t('discovery.filter')" />
            <v-btn class="modal-close" icon @click="onClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <CovidFilterMobile
              @set-covid="queryObject.covidSelectedOptions = $event"
              :preSelectedCovidFilter="preSelectedCovidFilter"
              :items="covidItems"
              ref="clearCovid"
            />
            <v-divider class="my-4" />
            <DateRangeMobile
              @set-date="queryObject.dateRange = $event"
              :preSelectedDateFilter="preSelectedDateFilter"
              ref="clearDate"
            />
            <v-divider class="my-4" />
            <PriceRangeMobile
              @set-prices="queryObject.priceRange = $event"
              :preSelectedPriceFilter="preSelectedPriceFilter"
              ref="clearPrice"
            />
            <v-divider class="my-4" />
            <CategorySelectMobile
              labelName="label"
              @set-categories="queryObject.categories = $event"
              :preselected="preSelectedCategoryFilter"
              ref="clearCategory"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-container
        fluid
        class="pa-3 popmodal-actions-container additional-buttons"
      >
        <v-row no-gutters>
          <v-col cols="12" class="d-flex justify-space-between">
            <v-btn
              text
              color="var(--v-grey7-base)"
              class="btn-reset-filter"
              width="158"
              height="48"
              @click.native="onClear"
            >
              {{ $t('discovery.reset_all') }}
            </v-btn>
            <v-btn
              @click="onSubmit"
              color="primary"
              :disabled="!addAppliedFilters() && !isPreselectedOptions"
              class="btn-submit-filter"
              width="158"
              height="48"
            >
              {{ $t('apply') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </v-dialog>
</template>

<script>
import DateRangeMobile from '@/modules/discovery/features/search/mobileFilters/DateRangeMobile';
import PriceRangeMobile from '@/modules/discovery/features/search/mobileFilters/PriceRangeMobile';
import CovidFilterMobile from '@/modules/discovery/features/search/mobileFilters/CovidFilterMobile';
import CategorySelectMobile from '@/modules/discovery/features/search/mobileFilters/CategorySelectMobile';
import BookingSearchMixins from '@/modules/discovery/mixins/BookingSearchMixins';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import ConfigsMixins from '@/core/mixins/ConfigsMixins';

export default {
  name: 'DiscoveryFilters',
  components: {
    CategorySelectMobile,
    CovidFilterMobile,
    DateRangeMobile,
    PriceRangeMobile,
  },
  mixins: [ConfigsMixins, BookingSearchMixins, GoogleTagManagerMixins],
  props: {
    triggerText: {
      default: 'Filters',
      type: String,
    },
    covidItems: Array,
    preSelectedCovidFilter: {
      type: Array,
      default: () => [],
    },
    preSelectedPriceFilter: {
      type: Object,
      default: () => ({ min: '', max: '' }),
    },
    preSelectedDateFilter: {
      type: Object,
      default: () => ({ min: '', max: '' }),
    },
    preSelectedCategoryFilter: {
      type: Array,
      default: () => [],
    },
    routeName: String,
  },

  data() {
    return {
      show: false,
      queryParams: [
        'start-date',
        'end-date',
        'sub-categories',
        'min-price',
        'max-price',
        'currency',
        'face-mask-required',
        'social-distancing-enforced',
        'intensified-cleaning',
      ],
      queryObject: {
        dateRange: {
          min: '',
          max: '',
        },
        priceRange: {
          min: '',
          max: '',
        },
        categories: [],
      },
      appliedFilters: 0,
    };
  },

  mounted() {
    const routeConfig = {
      name: this.routeName,
      params: { ...this.$route.params },
      query: { ...this.$route.query },
    };
    this.queryObject.dateRange.min = routeConfig.query['start-date'];
    this.queryObject.dateRange.max = routeConfig.query['end-date'];
    this.queryObject.categories = routeConfig.query['sub-categories'];
    this.queryObject.priceRange.min = routeConfig.query['min-price'];
    this.queryObject.priceRange.max = routeConfig.query['max-price'];
  },

  methods: {
    addAppliedFilters() {
      return (
        this.queryObject.categories?.length +
        +(
          !!this.queryObject.dateRange?.min || !!this.queryObject.dateRange?.max
        ) +
        +(
          !!this.queryObject.priceRange?.min ||
          !!this.queryObject.priceRange?.max
        ) +
        this.covidItems.filter((item) => item.toggle === true).length
      );
    },

    handleSelect() {
      const routeConfig = {
        name: this.routeName,
        params: { ...this.$route.params },
        query: { ...this.$route.query },
      };
      routeConfig.query['start-date'] = this.queryObject.dateRange?.min;
      routeConfig.query['end-date'] = this.queryObject.dateRange?.max;
      routeConfig.query['sub-categories'] = this.queryObject?.categories;
      routeConfig.query['min-price'] = this.queryObject.priceRange?.min;
      routeConfig.query['max-price'] = this.queryObject.priceRange?.max;
      routeConfig.query['currency'] = this.defaultCurrency;

      let covidQuery = [];
      this.covidItems.forEach((item) => {
        if (item.toggle === true) {
          covidQuery = [...covidQuery, item.query];
        }
      });
      covidQuery.forEach((item) => {
        routeConfig.query[item] = true;
      });

      this.$router.push(routeConfig).catch((err) => {});
      this.pushDataToAnalytic(covidQuery);
    },

    pushDataToAnalytic(covidQuery) {
      this.pushFilterAnalytics(this.$route.path, 'covid', covidQuery);
      this.pushFilterAnalytics(
        this.$route.path,
        'date',
        this.queryObject.dateRange
      );
      this.pushFilterAnalytics(
        this.$route.path,
        'category',
        this.queryObject.categories,
        this.allCategories
      );
      this.pushFilterAnalytics(
        this.$route.path,
        'price',
        [
          {
            min: this.queryObject.priceRange?.min,
            max: this.queryObject.priceRange?.max,
          },
        ],
        this.defaultCurrency
      );
    },

    removeQuery() {
      let query = Object.assign({}, this.$route.query);
      this.queryParams.forEach((item) => {
        delete query[item];
      });
      this.$router.replace({ query }).catch((err) => {});
    },

    onSubmit() {
      this.removeQuery();
      this.handleSelect();
      this.$emit('filtersSelected', this.queryObject);
      this.close();
    },

    onClear() {
      this.$refs.clearDate.clearValue();
      this.$refs.clearPrice.clearValue();
      this.$refs.clearCategory.clearValue();

      this.covidItems.forEach((item) => {
        item.toggle = false;
      });
      this.appliedFilters = 0;
      this.queryObject = {
        dateRange: {
          min: '',
          max: '',
        },
        priceRange: {
          min: '',
          max: '',
        },
        categories: [],
      };
      this.removeQuery();
      this.$emit('filtersSelected', this.queryObject);
      this.close();
    },

    close() {
      this.show = false;
    },

    onClose() {
      this.$refs.clearDate.setDefaultSelection();
      this.$refs.clearPrice.setDefaultSelection();
      this.$refs.clearCategory.setDefaultSelection();
      this.$refs.clearCovid.setDefaultSelection();
      this.close();
    },
  },

  computed: {
    isPreselectedOptions() {
      return (
        this.preSelectedCovidFilter.length ||
        this.preSelectedPriceFilter.max ||
        this.preSelectedPriceFilter.min ||
        this.preSelectedDateFilter.min ||
        this.preSelectedDateFilter.max ||
        this.preSelectedCategoryFilter.length
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.trigger-button {
  background: #fff !important;
  border: 1px solid #e0e0e0;
  border-radius: 26px !important;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 1.2;

  &::before {
    display: none;
  }
  &.active {
    border-color: var(--v-primary-base);
    color: var(--v-primary-base);

    & svg {
      fill: none;
      * {
        stroke: var(--v-primary-base);
      }
    }
  }
}

.trigger {
  margin-left: 8px;
}

.title {
  font-size: 1.25rem !important;
  font-weight: 600;
}

.modal-close {
  position: sticky;
  top: 20px;
  right: 20px;
  left: calc(100% - 55px);
  border-radius: 50%;
  background-color: #ffffff;
  z-index: 5;
}

.additional-buttons {
  & .btn-submit-filter,
  & .v-btn--text {
    @include font-size(16, 24, 600);
    text-transform: capitalize;
    border-radius: 8px;
    color: #fff;
  }

  & .btn-reset-filter {
    border: 2px solid #eee !important;
  }

  &
    .v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    color: #fff !important;
    background-color: #d1d1d1 !important;
  }
  border-radius: 8px;
  color: #fff;
}

.popmodal-actions-container {
  position: absolute;
  bottom: 0;
  background-color: #fff;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.1);
}
::v-deep {
  .v-dialog--active {
    margin: 0;
    overflow-x: hidden;
    max-height: 100%;
  }
}
</style>
