<template>
  <v-container class="pa-1" data-cy="CovidFilter">
    <v-row no-gutters>
      <v-col cols="12">
        <PopoverModal
          :disabledApply="!currentSelectedOptions.length && !hasSelectedItems"
          :isDiscoveryPage="isDiscoveryPage"
          :selected="hasSelectedItems"
          :title="title"
          :triggerText="buttonText"
          @submit="onSubmit"
          @clear="onClear"
          @close="setDefaultSelection"
          close-on-clear
          :depressedBtn="true"
          :primaryColor="'primary'"
        >
          <v-container class="covidFilter pa-0">
            <v-row no-gutters>
              <v-col cols="12">
                <v-list dense flat class="pt-2">
                  <v-list-item-group multiple>
                    <label
                      v-for="(option, i) in items"
                      :for="option.query"
                      :key="i"
                      @click.capture.stop="toggleSelect(option)"
                    >
                      <v-list-item>
                        <v-list-item-action>
                          <v-checkbox
                            :id="option.query"
                            v-model="option.toggle"
                            name="currentSelectedOptions"
                            color="primary"
                            @click="toggleSelect(option)"
                          />
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title class="item-label">
                            {{ option.label }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </label>
                  </v-list-item-group>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </PopoverModal>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PopoverModal from '@/core/components/PopoverModal';
import BookingSearchMixins from '../../mixins/BookingSearchMixins';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';

export default {
  name: 'CovidFilter',
  components: { PopoverModal },
  mixins: [BookingSearchMixins, GoogleTagManagerMixins],

  props: {
    isDiscoveryPage: {
      type: Boolean,
      default: false,
    },
    preSelectedCovidFilter: {
      type: Array,
      default: () => [],
    },
    routeName: String,
  },

  data() {
    return {
      hasSelectedItems: false,
      currentSelectedOptions: [],
      title: this.$t('discovery.covid_filter.title'),
      buttonText: this.$t('discovery.covid_filter.button'),
      items: [
        {
          label: this.$t('product.safety.face_masks'),
          query: 'face-mask-required',
          toggle: false,
        },
        {
          label: this.$t('product.safety.social_distancing_enforced'),
          query: 'social-distancing-enforced',
          toggle: false,
        },
        {
          label: this.$t('product.safety.intensified_cleaning'),
          query: 'intensified-cleaning',
          toggle: false,
        },
      ],
    };
  },

  created() {
    this.setDefaultSelection();
  },

  methods: {
    handleSelect(val) {
      const routeConfig = {
        name: this.routeName,
        params: { ...this.$route.params },
        query: { ...this.$route.query },
      };
      routeConfig.query[val] = true;
      this.$router.push(routeConfig).catch((err) => {});
    },

    toggleSelect(val) {
      if (
        this.currentSelectedOptions.includes(val.query) &&
        val.toggle === true
      ) {
        this.currentSelectedOptions = this.currentSelectedOptions.filter(
          (item) => item !== val.query
        );
      } else {
        this.currentSelectedOptions = [
          ...this.currentSelectedOptions,
          val.query,
        ];
      }

      this.currentSelectedOptions = [...new Set(this.currentSelectedOptions)];
    },

    onClear() {
      this.currentSelectedOptions = [];
      this.items.forEach((item) => {
        item.toggle = false;
      });
      this.hasSelectedItems = false;
      this.removeQuery();
      this.$emit('filtersSelected', this.currentSelectedOptions);
    },

    onSubmit() {
      let tempArray = [];

      this.items.forEach((item) => {
        if (item.toggle == true) {
          tempArray = [...tempArray, item.query];
        }
      });
      this.removeQuery();
      tempArray.forEach((item) => {
        this.handleSelect(item);
      });

      if (tempArray.length == 0) {
        this.hasSelectedItems = false;
      } else {
        this.hasSelectedItems = true;
      }
      this.pushFilterAnalytics(this.$route.path, 'covid', tempArray);
      this.$emit('filtersSelected', tempArray);
    },

    setDefaultSelection() {
      this.currentSelectedOptions = this.preSelectedCovidFilter || [];
      this.items.forEach((item) => {
        item.toggle = this.currentSelectedOptions.includes(item.query);
      });

      if (this.currentSelectedOptions.length) {
        this.hasSelectedItems = true;
      } else {
        this.hasSelectedItems = false;
      }
    },

    removeQuery() {
      let query = Object.assign({}, this.$route.query);
      delete query['face-mask-required'];
      delete query['social-distancing-enforced'];
      delete query['intensified-cleaning'];
      this.$router.replace({ query }).catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.covidFilter {
  min-width: 352px;

  & .v-list-item {
    min-height: 48px;
  }
  & .v-list-item__action {
    &:first-child {
      margin-right: 11px;
    }
    & .v-input--checkbox {
      position: absolute;
      top: 12px;
      & .v-icon {
        color: #9d9d9d;
      }
    }
  }
}

::v-deep .v-list-item__title.item-label {
  @include font-size(16, 24);
  color: #05090f;
}
</style>
