<template>
  <v-container class="pa-1" data-cy="PriceRangeFilter">
    <PopoverModal
      :disabledApply="
        !currentMinValue &&
          !currentMaxValue &&
          !hasSelectedPrices &&
          !hasPreselectedPrices
      "
      :isDiscoveryPage="isDiscoveryPage"
      :selected="hasSelectedPrices"
      :title="$t('common.choose_price_range')"
      :triggerText="displayedSelectedPrices()"
      @submit="onSave"
      @clear="onClear"
      @close="setDefaultSelection"
      close-on-clear
      :depressedBtn="true"
      :primaryColor="'primary'"
    >
      <v-container>
        <v-row class="price-fields pa-3">
          <v-col>
            <p class="font-weight-bold price mb-1">
              {{ $t('discovery.min_price') }}
            </p>
            <v-text-field
              dense
              flat
              outlined
              placeholder="$"
              v-model.number="currentMinValue"
              v-on:keypress="onMinValueKeyPress($event)"
              @click="clearMinInput()"
              class="search-input"
              data-cy="PriceRangeFilter-lowerPrice"
            >
            </v-text-field>
          </v-col>
          <v-col class="d-flex align-center pa-3 pt-7">
            <span class="text-center">{{ $t('to').toLowerCase() }}</span>
          </v-col>
          <v-col>
            <p class="mb-1 price font-weight-bold">
              {{ $t('discovery.max_price') }}
            </p>
            <v-text-field
              dense
              outlined
              placeholder="$"
              v-model.number="currentMaxValue"
              v-on:keypress="onMaxValueKeyPress($event)"
              @click="clearMaxInput()"
              class="search-input"
              data-cy="PriceRangeFilter-higherPrice"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </PopoverModal>
  </v-container>
</template>

<script>
import PopoverModal from '@/core/components/PopoverModal';
import ConfigsMixins from '@/core/mixins/ConfigsMixins.vue';
import { currencyTextFormatter } from '@/core/filters/currency';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
export default {
  name: 'PriceRangeFilter',
  mixins: [ConfigsMixins, GoogleTagManagerMixins],
  components: { PopoverModal },
  props: {
    isDiscoveryPage: {
      type: Boolean,
      default: false,
    },
    preSelectedPriceFilter: {
      type: Object,
      default: () => ({ min: '', max: '' }),
    },
    routeName: String,
  },
  data() {
    return {
      currentMinValue: '',
      currentMaxValue: '',
      submittedPrices: [{ min: '', max: '' }],
      currency: this.defaultCurrency,
    };
  },

  created() {
    this.setDefaultSelection();
  },

  methods: {
    onSave() {
      this.submittedPrices = [{ min: '', max: '' }];
      if (this.validateValue()) {
        this.submittedPrices = [
          { min: this.selectedPrices.min, max: this.selectedPrices.max },
        ];
        this.submitPriceFilter();
      }
    },
    onClear() {
      this.currentMinValue = '';
      this.currentMaxValue = '';
      this.submittedPrices = [{ min: '', max: '' }];
      this.submitPriceFilter();
    },
    clearMinInput() {
      this.currentMinValue = '';
      this.submittedPrices = [{ min: '', max: this.selectedPrices.max }];
    },
    clearMaxInput() {
      this.currentMaxValue = '';
      this.submittedPrices = [{ min: this.selectedPrices.min, max: '' }];
    },
    submitPriceFilter() {
      this.pushFilterAnalytics(
        this.$route.path,
        'price',
        this.submittedPrices,
        this.defaultCurrency
      );

      this.$emit(
        'priceRangeSelected',
        this.submittedPrices,
        this.defaultCurrency
      );
      this.updatePriceQuery();
    },
    updatePriceQuery() {
      const routeConfig = {
        name: this.routeName,
        params: { ...this.$route.params },
        query: {
          ...this.$route.query,
        },
      };
      routeConfig.query['min-price'] = this.selectedPrices.min;
      routeConfig.query['max-price'] = this.selectedPrices.max;
      routeConfig.query['currency'] = this.defaultCurrency;
      this.$router.push(routeConfig).catch((err) => {});
    },
    onMinValueKeyPress(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      //Check that the key pressed is numerical
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        // Check that the key pressed is enter or return key
        if (charCode == 13) {
          this.validateValue();
        }
        return true;
      }
    },
    onMaxValueKeyPress(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        if (charCode == 13) {
          this.onSave();
        }
        return true;
      }
    },

    validateValue() {
      if (this.currentMinValue === '' && this.currentMaxValue === '') {
        return true;
      } else {
        if (this.currentMinValue === this.currentMaxValue) {
          this.currentMinValue = this.currentMinValue - 1;
        }
        if (
          this.currentMaxValue !== '' &&
          this.currentMaxValue < this.currentMinValue
        ) {
          [this.currentMaxValue, this.currentMinValue] = [
            this.currentMinValue,
            this.currentMaxValue,
          ];
        }
        return true;
      }
    },

    formatPrice(price) {
      return this.$options.filters.currency(price, this.defaultCurrency);
    },
    setDefaultSelection() {
      let minPrice = this.preSelectedPriceFilter.min || '';
      let maxPrice = this.preSelectedPriceFilter.max || '';

      if (minPrice || maxPrice) {
        this.currentMinValue = minPrice;
        this.currentMaxValue = maxPrice;
        this.submittedPrices = [{ min: minPrice, max: maxPrice }];
        this.selectedPrices.min = minPrice;
        this.selectedPrices.max = maxPrice;

        this.displayedSelectedPrices();
      } else {
        this.currentMinValue = '';
        this.currentMaxValue = '';
        this.submittedPrices = [{ min: '', max: '' }];
      }
    },

    displayedSelectedPrices() {
      let formattedPrice;
      let minFormattedPrice;
      let maxFormattedPrice;
      if (!this.hasSelectedPrices) {
        return this.$t('common.price');
      } else {
        if (
          this.submittedPrices[0].min !== '' &&
          this.submittedPrices[0].max === ''
        ) {
          formattedPrice = this.formatPrice(this.submittedPrices[0].min);
          return this.$t('common.from_price', { price: formattedPrice });
        } else if (
          this.submittedPrices[0].max !== '' &&
          this.submittedPrices[0].min === ''
        ) {
          formattedPrice = this.formatPrice(this.submittedPrices[0].max);
          return this.$t('common.to_price', { price: formattedPrice });
        } else {
          minFormattedPrice = this.formatPrice(this.submittedPrices[0].min);
          maxFormattedPrice = this.formatPrice(this.submittedPrices[0].max);
          return `${minFormattedPrice} - ${maxFormattedPrice}`;
        }
      }
    },
  },

  computed: {
    currencyText() {
      return currencyTextFormatter(this.defaultCurrency);
    },
    selectedPrices() {
      return {
        min: this.currentMinValue,
        max: this.currentMaxValue,
      };
    },
    hasSelectedPrices() {
      return (
        this.submittedPrices[0].min !== '' || this.submittedPrices[0].max !== ''
      );
    },

    hasPreselectedPrices() {
      return (
        this.preSelectedPriceFilter.min !== '' ||
        this.preSelectedPriceFilter.max !== ''
      );
    },
  },
  watch: {
    preSelectedPriceFilter: {
      deep: true,
      handler() {
        this.setDefaultSelection();
      },
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .v-input__slot {
    border: 1px solid #d1d1d1;
    border-radius: 8px;
    width: 146px;
    min-height: 44px !important;
    & fieldset {
      border: none;
    }
  }
  .v-text-field__details {
    display: none;
  }
}

.price {
  color: #2f2f2f;
  font-size: 14px;
}

.price-fields {
  & .col {
    padding: 0;
  }
}
</style>
