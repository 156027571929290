<template>
  <v-container class="pa-1" data-cy="DateRangePopover">
    <PopoverModal
      :isDiscoveryPage="isDiscoveryPage"
      :disabledApply="!date"
      :selected="hasSelectedDates"
      :title="$t('discovery.choose_date_ange')"
      :triggerText="$t('discovery.date')"
      @submit="onSubmit"
      @clear="onClear"
      @close="setDefaultSelection"
      close-on-clear
      :depressedBtn="true"
      :primaryColor="'primary'"
    >
      <v-date-picker
        v-model="date"
        :allowed-dates="allowedDates"
        :picker-date.sync="pickerDate"
        color="primary"
        :elevation="0"
        no-title
        range
        show-adjacent-months
        class="date-picker elevation-0"
        @click:date="onDateClick"
        :locale="userPreferredLanguage"
        height="380"
        :width="$vuetify.breakpoint.mdAndDown ? '100%' : '352'"
      >
      </v-date-picker>
    </PopoverModal>
  </v-container>
</template>

<script>
import LanguageMixins from '@/core/mixins/LanguageMixins';
import PopoverModal from '@/core/components/PopoverModal';
import BookingSearchMixins from '../../mixins/BookingSearchMixins';
import moment from 'moment';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';

export default {
  name: 'DateRangePopover',
  components: { PopoverModal },
  mixins: [LanguageMixins, BookingSearchMixins, GoogleTagManagerMixins],
  props: {
    isDiscoveryPage: {
      type: Boolean,
      default: false,
    },
    preSelectedDateFilter: {
      type: Object,
      default: () => ({ min: '', max: '' }),
    },
    routeName: String,
  },

  data() {
    return {
      date: '',
      selectedDates: {
        min: '',
        max: '',
      },
      sortedDates: [],
      pickerDate: null,
    };
  },

  created() {
    this.setDefaultSelection();
  },

  computed: {
    // Set enddate if one date is selected
    dateRange() {
      return [
        this.date[0],
        Boolean(this.date[1]) ? this.date[1] : this.date[0],
      ];
    },

    hasSelectedDates() {
      return !!this.sortedDates.length;
    },
  },

  methods: {
    formatDate(val) {
      return moment(new Date(val)).format('YYYY-MM-DD');
    },

    onDateClick(e) {
      this.sortedDates = [];
    },

    removeQuery() {
      let query = Object.assign({}, this.$route.query);
      delete query['start-date'];
      delete query['end-date'];
      this.$router.replace({ query }).catch((err) => {});
    },

    onClear() {
      this.date = '';
      this.sortedDates = [];
      this.selectedDates = {
        min: '',
        max: '',
      };
      this.removeQuery();
      this.clearDefaultSelection();
    },

    onSubmit() {
      if (this.date) {
        this.sortedDates =
          this.dateRange[0] === this.dateRange[1]
            ? [this.dateRange[0], this.dateRange[1]]
            : this.dateRange.slice().sort((a, b) => new Date(a) - new Date(b));
        let startDate = this.sortedDates[0];
        let endDate = this.sortedDates[1];

        this.selectedDates = {
          min: startDate || '',
          max: endDate || '',
        };
      }
      this.pushFilterAnalytics(this.$route.path, 'date', this.selectedDates);
      this.updateFilterQuery();
    },

    setDefaultSelection() {
      let startDate = this.preSelectedDateFilter.min || '';
      let endDate = this.preSelectedDateFilter.max || '';
      if (startDate || endDate) {
        this.sortedDates = [startDate, endDate];
        this.selectedDates.min = startDate;
        this.selectedDates.max = endDate;
        this.date = [this.formatDate(startDate), this.formatDate(endDate)];
      } else {
        this.selectedDates.min = '';
        this.selectedDates.max = '';
        this.sortedDates = [];
        this.date = [
          this.formatDate(this.getTodaysDate()),
          this.formatDate(this.getTodaysDate()),
        ];
      }
    },

    clearDefaultSelection() {
      this.date = '';
      this.pickerDate = moment(this.getTodaysDate()).format('YYYY-MM');
    },

    updateFilterQuery() {
      const routeConfig = {
        name: this.routeName,
        params: { ...this.$route.params },
        query: { ...this.$route.query },
      };

      routeConfig.query['start-date'] = this.selectedDates.min;
      routeConfig.query['end-date'] = this.selectedDates.max;
      this.$router.push(routeConfig).catch((err) => {});
    },

    getTodaysDate() {
      return new Date(Date.now());
    },

    allowedDates(val) {
      const now = this.getTodaysDate();
      const yesterday = new Date(now.setDate(now.getDate()));
      return (
        new Date(val).setHours(0, 0, 0, 0) >= yesterday.setHours(0, 0, 0, 0)
      );
    },
  },
  watch: {
    preSelectedDateFilter: {
      deep: true,
      handler() {
        this.setDefaultSelection();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-list-item__title.item-label {
  @include font-size(16, 24);
  color: #05090f;
}

::v-deep {
  & .sub-label {
    @include font-size(14, 20);
    color: var(--v-grey7-base);
    white-space: initial;
  }

  & .v-date-picker-table__current {
    border: none;
    background-color: #eeeeee;
  }

  & .v-date-picker-header {
    padding-bottom: 10px;
  }

  & .v-date-picker-header__value {
    font-size: 16px;
  }

  & .v-date-picker-table {
    & thead th {
      border-bottom: 1px solid #eeeeee;
      font-size: 14px;
    }
    & table {
      border-collapse: collapse;
    }

    & tbody {
      & td {
        padding: 4px 1px;
        & button {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
