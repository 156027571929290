<template>
  <v-container class="category-select px-0">
    <v-row no-gutters>
      <v-col cols="12">
        <span class="subtitle" v-text="$tc('discovery.category.button', 0)" />
      </v-col>
      <v-col cols="12">
        <v-checkbox
          v-for="(option, i) in allCategories.items"
          :key="i"
          :id="option.id"
          v-model="currentSelectedOptions"
          name="currentSelectedOptions"
          color="primary"
          :value="option.id"
          class="categories-checkbox px-4"
          hide-details
        >
          <template v-slot:label>
            {{ option[labelName] }}
          </template>
        </v-checkbox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BookingSearchMixins from '@/modules/discovery/mixins/BookingSearchMixins';

export default {
  name: 'CategorySelectMobile',
  mixins: [BookingSearchMixins],
  props: {
    preselected: {
      type: Array,
      default: () => [],
    },
    labelName: String,
  },

  data() {
    return {
      currentSelectedOptions: [],
      currentSelectedOptionsLength: 0,
    };
  },

  created() {
    this.setDefaultSelection();
    this.loadCategories({ purchaseStrategy: 'Bookable' });
  },

  computed: {
    hasSelectedItems() {
      return !!this.currentSelectedOptions.length;
    },
  },

  watch: {
    preselected: {
      deep: true,
      handler() {
        this.setDefaultSelection();
      },
    },

    currentSelectedOptions: {
      deep: true,
      handler() {
        this.$emit('set-categories', this.currentSelectedOptions);
      },
    },
  },

  methods: {
    setDefaultSelection() {
      this.currentSelectedOptions = this.preselected || [];
      this.currentSelectedOptionsLength = this.currentSelectedOptions.length;
    },

    clearValue() {
      this.currentSelectedOptions = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.category-select {
  padding-bottom: 80px;
}
.subtitle {
  display: block;
  padding-bottom: 1rem;
  font-weight: 600;
  font-size: 1rem;
}

::v-deep {
  & .categories-checkbox {
    &.v-input--selection-controls {
      &.v-input {
        padding-left: 0 !important;
        &:first-child {
          margin-top: 0;
        }
      }
    }

    & .v-input__slot {
      & label {
        color: #05090f;
        padding-right: 10px;
        font-size: 16px;
      }
    }
  }
}
</style>
