<template>
  <BaseFilterComponentDiscover
    :title="listTitle"
    :selected="hasSelectedItems"
    :displayedBtnText="
      $tc('discovery.category.button', currentSelectedOptionsLength)
    "
    :class="{ 'active-button': currentSelectedOptionsLength }"
    @save="onSave"
    @clear="onClear"
    @close="setDefaultSelection"
  >
    <template v-if="!loading">
      <v-container class="pt-0">
        <v-row no-gutters>
          <v-col cols="12">
            <v-checkbox
              v-for="(option, i) in itemList"
              :key="i"
              :id="option.id"
              v-model="currentSelectedOptions"
              name="currentSelectedOptions"
              color="primary"
              :value="option.id"
              class="categories-checkbox px-4"
              hide-details
            >
              <template v-slot:label>
                {{ option[labelName] }}
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </BaseFilterComponentDiscover>
</template>

<script>
import BaseFilterComponentDiscover from '@/modules/common/filters/BaseFilterComponentDiscover';

export default {
  name: 'MultiSelectComponent',
  components: { BaseFilterComponentDiscover },
  props: {
    itemList: {
      type: Array,
      default: () => [],
    },
    preselected: {
      type: Array,
      default: () => [],
    },
    labelName: String,
    listTitle: String,
    loading: Boolean,
  },

  data() {
    return {
      currentSelectedOptions: [],
      currentSelectedOptionsLength: 0,
    };
  },

  created() {
    this.setDefaultSelection();
  },

  computed: {
    hasSelectedItems() {
      return !!this.currentSelectedOptions.length || !!this.preselected.length;
    },
  },

  watch: {
    preselected: {
      deep: true,
      handler() {
        this.setDefaultSelection();
      },
    },
  },

  methods: {
    onSave() {
      this.submitSelectedFilters();
    },

    onClear() {
      this.currentSelectedOptions = [];
      this.submitSelectedFilters();
    },

    submitSelectedFilters() {
      this.$emit('onFilterSelected', this.currentSelectedOptions);
      this.currentSelectedOptionsLength = this.currentSelectedOptions.length;
    },

    setDefaultSelection() {
      this.currentSelectedOptions = this.preselected || [];
      this.currentSelectedOptionsLength = this.currentSelectedOptions.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.active-button {
  ::v-deep {
    .v-btn {
      border-color: var(--v-primary-base);

      .trigger {
        color: var(--v-primary-base) !important;
      }
    }
  }
}

::v-deep {
  & .categories-checkbox {
    &.v-input--selection-controls {
      &.v-input {
        padding-left: 10px !important;
        &:first-child {
          margin-top: 0;
        }
      }
    }

    & .v-input__slot {
      & label {
        color: #05090f;
        padding-right: 10px;
        font-size: 16px;
      }
    }
  }
}
</style>
