<template>
  <v-container class="results pt-8 pt-sm-10 px-2 px-md-0 pb-0">
    <v-row no-gutters>
      <v-col class="d-flex align-center" cols="5">
        <template v-if="isSearching">
          <v-skeleton-loader type="text" :tile="false" width="200" />
        </template>

        <template v-else>
          <h2
            data-cy="ResultsPage-Summary"
            class="summary-text"
            v-html="
              $vuetify.breakpoint.mdAndUp
                ? resultSummary
                : $t('discovery.experiences', { total: searchResults.total })
            "
          ></h2>
        </template>
      </v-col>

      <v-col cols="7" class="d-flex justify-end flex-wrap">
        <template v-if="$vuetify.breakpoint.mdAndUp">
          <CovidFilter
            :items="covidItems"
            :isDiscoveryPage="isDiscoveryPage"
            :preSelectedCovidFilter="selectedCovid"
            :routeName="routeName"
            style="width: auto"
            class="ma-0"
          />
          <DateRangePopover
            :isDiscoveryPage="isDiscoveryPage"
            :preSelectedDateFilter="selectedDates"
            :routeName="routeName"
            style="width: auto"
            class="ma-0"
          />
          <PriceRangeFilter
            :isDiscoveryPage="isDiscoveryPage"
            @priceRangeSelected="getPrices"
            :preSelectedPriceFilter="selectedPriceRange"
            :routeName="routeName"
            style="width: auto"
            class="ma-0"
          />
          <CategoryFilter
            @categoriesSelected="pickCategory"
            :preSelectedCategoryFilter="selectedCategories.items"
            :routeName="routeName"
            style="width: auto"
            class="ma-0"
          />
        </template>

        <template v-else>
          <DiscoveryFilters
            @clear-filter="toggleCovidItems"
            :covidItems="covidItems"
            :preSelectedCovidFilter="selectedCovid"
            :preSelectedDateFilter="selectedDates"
            :preSelectedPriceFilter="selectedPriceRange"
            :preSelectedCategoryFilter="selectedCategories.items"
            :routeName="routeName"
          />
        </template>
      </v-col>
    </v-row>

    <template v-if="hasSearchResults">
      <v-row justify="start">
        <v-col
          :cols="resultsColSize"
          v-for="(item, index) in searchResults.items"
          :key="index"
        >
          <div @click="onProductClick(item)">
            <router-link
              class="link results-product-link primary--text font-weight-bold"
              :to="{ name: 'booking-details', params: { id: item.id } }"
            >
              <ProductItemComponent
                :title="item.title"
                :thumbnail="item.thumbnail"
                :priceValue="item.priceStartingAt.value"
                :priceCurrency="item.priceStartingAt.baseCurrency"
                :aspectRatio="1.5"
                isResults
              />
            </router-link>
          </div>
        </v-col>
      </v-row>
    </template>

    <template v-if="!hasSearchResults && !isSearching">
      <v-row no-gutters>
        <v-col class="d-flex flex-column text-center mt-6 mt-md-12">
          <span class="title text-light-gray-color">
            {{ $t('no_results_found') }}
          </span>
          <span
            class="
              mt-2
              subtitle-2
              text-light-gray-color
              font-weight-bold
              search-subtitle
            "
          >
            {{ $t('update_keywords_show_more_experiences') }}
          </span>
        </v-col>
      </v-row>
    </template>

    <template v-if="isSearching || isPaginatingSearch">
      <v-row no-gutters>
        <v-col cols="12">
          <v-row
            v-for="rowIndex in 4"
            :key="rowIndex + 10"
            justify="space-between"
          >
            <v-col
              v-for="colIndex in 12 / resultsColSize"
              :key="colIndex + 20"
              :cols="resultsColSize"
            >
              <v-skeleton-loader type="card" :loading="true" :tile="false" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>

    <template v-if="canShowLoadMoreResultsButton">
      <v-row class="pt-6">
        <v-col cols="12" class="d-flex justify-center">
          <v-btn
            class="results-more"
            color="secondary"
            depressed
            dense
            height="44"
            @click="
              loadSearchContent(searchResults.items.length, resultsPageSize)
            "
          >
            {{ $t('load_more_results') }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import BookingSearchMixins from './mixins/BookingSearchMixins';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import ProductItemComponent from './features/discovery-list/ProductItemComponent';
import DateRangePopover from './features/search/DateRangePopover';
import PriceRangeFilter from '@/modules/common/filters/PriceRangeFilter';
import CategoryFilter from './features/search/CategoryFilter';
import CovidFilter from './features/search/CovidFilter';
import DiscoveryFilters from '@/modules/common/filters/DiscoveryFilters';
import arraysUtils from '@/core/utils/arrays';
import ConfigsMixins from '@/core/mixins/ConfigsMixins.vue';
import HotJarMixins from '@/core/mixins/HotJarMixins';

export default {
  name: 'ResultsPage',
  mixins: [
    BookingSearchMixins,
    GoogleTagManagerMixins,
    HotJarMixins,
    ConfigsMixins,
  ],

  components: {
    ProductItemComponent,
    DateRangePopover,
    PriceRangeFilter,
    CategoryFilter,
    CovidFilter,
    DiscoveryFilters,
  },

  props: {
    query: String,
    lat: Number,
    lng: Number,
    city: String,
    categoryTitle: String,
    subCategories: {
      type: Array,
      default: () => [],
    },
    availabilityStart: String,
    availabilityEnd: String,
    minPrice: Number,
    maxPrice: Number,
    faceMaskRequired: {
      type: Boolean,
      default: false,
    },
    socialDistancingEnforced: {
      type: Boolean,
      default: false,
    },
    intensifiedCleaning: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isDiscoveryPage: true,
      currency: null,
      covidItems: [
        {
          label: this.$t('product.safety.face_masks'),
          query: 'face-mask-required',
          toggle: false,
        },
        {
          label: this.$t('product.safety.social_distancing_enforced'),
          query: 'social-distancing-enforced',
          toggle: false,
        },
        {
          label: this.$t('product.safety.intensified_cleaning'),
          query: 'intensified-cleaning',
          toggle: false,
        },
      ],
    };
  },

  computed: {
    resultSummary() {
      let summaryText;
      if (this.searchResults && this.searchResults.total) {
        let formattedQuery = this.query
          ? this.query.replace(/^\w/, (c) => c.toUpperCase())
          : '';
        if (this.city && this.query) {
          summaryText = this.$t('discovery.experiences_found_for_in', {
            total: this.searchResults.total,
            query: this.query.replace(/^\w/, (c) => c.toUpperCase()),
            city: this.city,
          });
        } else if (this.city) {
          summaryText = this.$t('discovery.experiences_found_in', {
            total: this.searchResults.total,
            city: this.city,
          });
        } else if (this.query) {
          summaryText = this.$t('discovery.experiences_found_for', {
            total: this.searchResults.total,
            query: this.query.replace(/^\w/, (c) => c.toUpperCase()),
          });
        }

        return summaryText;
      } else return null;
    },

    selectedCovid() {
      let tmpArray = [];
      let query = this.$route.query;
      if (this.faceMaskRequired || 'face-mask-required' in query) {
        tmpArray.push('face-mask-required');
      }
      if (
        this.socialDistancingEnforced ||
        'social-distancing-enforced' in query
      ) {
        tmpArray.push('social-distancing-enforced');
      }
      if (this.intensifiedCleaning || 'intensified-cleaning' in query) {
        tmpArray.push('intensified-cleaning');
      }

      return tmpArray;
    },

    routeName() {
      return !!this.query
        ? 'discoverySearchResults'
        : 'discoveryCategoryResults';
    },

    selectedDates() {
      return {
        min: this.availabilityStart || '',
        max: this.availabilityEnd || '',
      };
    },

    selectedPriceRange() {
      return {
        min: this.minPrice || '',
        max: this.maxPrice || '',
      };
    },

    selectedCategories() {
      return {
        items: this.subCategories || [],
      };
    },

    resultsPageSize() {
      let pageSize = 15;
      if (this.$vuetify.breakpoint.smOnly) {
        pageSize = 12;
      } else if (this.$vuetify.breakpoint.mdOnly) {
        pageSize = 15;
      } else if (this.$vuetify.breakpoint.lgOnly) {
        pageSize = 20;
      } else if (this.$vuetify.breakpoint.xlOnly) {
        pageSize = 20;
      } else if (this.$vuetify.breakpoint.xsOnly) {
        pageSize = 12;
      }
      return pageSize;
    },

    resultsColSize() {
      let colSize = 4;
      if (this.$vuetify.breakpoint.smOnly) {
        colSize = 12;
      } else if (this.$vuetify.breakpoint.mdOnly) {
        colSize = 6;
      } else if (this.$vuetify.breakpoint.lgOnly) {
        colSize = 4;
      } else if (this.$vuetify.breakpoint.xlOnly) {
        colSize = 3;
      } else if (this.$vuetify.breakpoint.xsOnly) {
        colSize = 12;
      }
      return colSize;
    },

    canShowLoadMoreResultsButton() {
      return (
        !this.isSearching && !this.isPaginatingSearch && this.canLoadMoreResults
      );
    },

    hasPreviousSearchResults() {
      return this.hasSearchResults && this.matchedSearchResultsParams;
    },

    matchedSearchResultsParams() {
      return (
        this.searchResults.latitude === this.lat &&
        this.searchResults.longitude === this.lng &&
        this.searchResults.searchText === this.query &&
        this.searchResults.minAvailabilityDate === this.selectedDates.min &&
        this.searchResults.maxAvailabilityDate === this.selectedDates.max &&
        this.searchResults.minPrice === this.selectedPriceRange.min &&
        this.searchResults.maxPrice === this.selectedPriceRange.max &&
        !!this.searchResults.faceMaskRequired === !!this.faceMaskRequired &&
        !!this.searchResults.socialDistancingEnforced ===
          !!this.socialDistancingEnforced &&
        !!this.searchResults.intensifiedCleaning ===
          !!this.intensifiedCleaning &&
        arraysUtils.equals(
          this.searchResults.subCategories,
          this.selectedCategories.items
        )
      );
    },

    resultsTitle() {
      let title = null;
      if (!!this.query) {
        title = this.query;
      } else if (!!this.categoryTitle) {
        title = this.categoryTitle;
      }
      return title;
    },
  },

  created() {
    this.currency = this.$route.query.currency || this.defaultCurrency;
    if (!this.hasPreviousSearchResults) {
      this.loadSearchContent(0, this.resultsPageSize);
    }
  },

  watch: {
    query(value, oldVal) {
      if (value !== oldVal) {
        this.loadSearchContent(0, this.resultsPageSize);
      }
    },

    city(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadSearchContent(0, this.resultsPageSize);
      }
    },

    selectedCovid: {
      deep: true,
      handler() {
        this.goToHookHash();
        this.loadSearchContent(0, this.resultsPageSize);
      },
    },

    selectedCategories: {
      deep: true,
      handler() {
        this.goToHookHash();
        this.loadSearchContent(0, this.resultsPageSize);
      },
    },

    selectedDates: {
      deep: true,
      handler() {
        this.goToHookHash();
        this.loadSearchContent(0, this.resultsPageSize);
      },
    },

    selectedPriceRange: {
      deep: true,
      handler() {
        this.goToHookHash();
        this.loadSearchContent(0, this.resultsPageSize);
      },
    },
  },

  methods: {
    toggleCovidItems() {
      this.covidItems.forEach((item) => {
        item.toggle = false;
      });
    },

    goToHookHash() {
      window.scrollTo({
        top: 0,
        behavior: 'instant',
      });
    },

    onProductClick(product) {
      this.pushExperiencesItemClickAnalytics(
        product,
        this.query,
        'list',
        'Booking - Results Page'
      );
      this.pushHotJarAnalytics('experiences_item_click');
    },

    getPrices(prices, currency) {
      this.selectedPriceRange.min = prices[0].min;
      this.selectedPriceRange.max = prices[0].max;
      this.currency = currency;
    },

    pickCategory(ids) {
      this.selectedCategories.items = [...ids];
    },

    loadSearchContent(skip, take) {
      this.searchBookingContent({
        city: this.city,
        searchText: this.query,
        latitude: this.lat || '',
        longitude: this.lng || '',
        minAvailabilityDate: this.selectedDates ? this.selectedDates.min : '',
        maxAvailabilityDate: this.selectedDates ? this.selectedDates.max : '',
        minPrice: this.selectedPriceRange ? this.selectedPriceRange.min : '',
        maxPrice: this.selectedPriceRange ? this.selectedPriceRange.max : '',
        currency: this.currency,
        skip: skip,
        take: take,
        subCategories: this.selectedCategories.items,
        faceMaskRequired: this.selectedCovid.includes('face-mask-required')
          ? true
          : null,
        socialDistancingEnforced: this.selectedCovid.includes(
          'social-distancing-enforced'
        )
          ? true
          : null,
        intensifiedCleaning: this.selectedCovid.includes('intensified-cleaning')
          ? true
          : null,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.results {
  margin-top: 53px;

  @media (max-width: map-get($grid-breakpoints, sm)) {
    margin-top: 16px;
  }

  &-product-link {
    text-decoration: none;
  }
}

.search-subtitle {
  line-height: 25px;
}

.text-light-gray-color {
  color: #857f72;
}

.results-more {
  color: var(--v-primary-base) !important;
  background-color: transparent !important;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--v-primary-base);
    opacity: 0.07;
  }
}

.summary-text {
  @include font-size(18, 150);
  color: #05090f;

  @media (min-width: map-get($grid-breakpoints, md)) {
    font-size: 20px;
  }
}
</style>
