<template>
  <v-container class="pa-1">
    <v-menu
      v-model="showMenu"
      offset-y
      attach
      :left="$vuetify.breakpoint.mdAndUp"
      nudge-bottom="3"
      nudge-left="2"
      transition="slide-y-transition"
      :close-on-content-click="false"
      :max-height="maxHeight"
      min-width="352"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          outlined
          v-on="on"
          height="32"
          :small="$vuetify.breakpoint.mdAndDown"
          :class="[
            $vuetify.breakpoint.mdAndUp ? 'action-btn' : 'action-btn-mobile',
            'font-weight-bold text-capitalize',
          ]"
        >
          <span class="trigger">
            {{ displayedBtnText }}
          </span>
        </v-btn>
      </template>
      <v-card :elevation="0">
        <div class="top-overlay"></div>
        <v-row no-gutters>
          <v-col>
            <slot></slot>
          </v-col>
        </v-row>
      </v-card>
      <v-row class="filter-bottom pa-3" no-gutters justify="space-between">
        <slot name="footer">
          <v-col cols="auto" class="additional-buttons">
            <v-btn
              text
              color="var(--v-grey7-base)"
              class="btn-reset-filter"
              width="158"
              height="48"
              @click="onClear()"
            >
              {{ $t('clear') }}
            </v-btn>
          </v-col>
          <v-col cols="auto" class="additional-buttons">
            <v-btn
              @click="onSave()"
              class="btn-submit-filter"
              color="primary"
              depressed
              :disabled="!selected"
              width="158"
              height="48"
            >
              {{ $t('apply') }}
            </v-btn>
          </v-col>
        </slot>
      </v-row>
    </v-menu>
  </v-container>
</template>

<script>
export default {
  name: 'BaseFilterComponentDiscover',
  props: {
    title: {
      default: function() {
        return this.$t('common.choose_price_range');
      },
      type: String,
    },
    selected: {
      type: Boolean,
    },
    displayedBtnText: {
      type: String,
    },
  },

  data() {
    return {
      showMenu: false,
      maxHeight: 500,
    };
  },

  methods: {
    onClear() {
      this.showMenu = false;
      this.$emit('clear');
    },

    onSave() {
      this.showMenu = false;
      this.$emit('save');
    },
  },

  watch: {
    showMenu: {
      handler(val) {
        if (val === false) {
          this.$emit('close');
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-header {
  user-select: none;
  color: #858f9d;
}
.small-text {
  font-size: 8px;
}

.action-btn {
  background: #fff;
  border: 1px solid #cfd5e0;
  @include font-size(14, 20, 600);
  border-radius: 26px !important;

  &-mobile {
    font-size: 12px;
  }

  &.primary {
    color: #fff;
  }
}
.trigger {
  @include font-size(16, 150);
  text-align: center;
  color: #05090f;
  .active-button & {
    font-weight: 500 !important;
  }
}
.additional-buttons {
  &
    .v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    color: #fff !important;
    background-color: #d1d1d1 !important;
  }
  & .v-btn--text,
  .btn-submit-filter {
    @include font-size(16, 24, 600);
    text-transform: capitalize;
  }
  & .btn-submit-filter {
    border-radius: 8px;
    color: #fff;
  }
  & .btn-reset-filter {
    border: 2px solid #eee !important;
  }
}

.filter-bottom {
  position: sticky;
  bottom: 0;
  background-color: #fff;
}

.v-menu__content {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.top-overlay {
  position: sticky;
  top: 0;
  width: 100%;
  height: 12px;
  background-color: #fff;
  z-index: 1;
}
</style>
