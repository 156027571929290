<template>
  <MultiSelectComponent
    data-cy="CategoryFilter"
    :itemList="allCategories.items"
    :preselected="preSelectedCategoryFilter"
    labelName="label"
    :loading="isLoadingCategories"
    :routeName="routeName"
    :listTitle="title"
    @onFilterSelected="handleSelectedFilters"
  />
</template>

<script>
import MultiSelectComponent from './MultiSelectComponent';
import BookingSearchMixins from '../../mixins/BookingSearchMixins';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';

export default {
  name: 'CategoryFilter',
  components: { MultiSelectComponent },
  mixins: [BookingSearchMixins, GoogleTagManagerMixins],
  props: {
    preSelectedCategoryFilter: {
      type: Array,
      default: () => [],
    },
    routeName: String,
  },

  data() {
    return {
      title: this.$t('discovery.category.title'),
    };
  },

  created() {
    this.loadCategories({ purchaseStrategy: 'Bookable' });
  },

  methods: {
    handleSelectedFilters(selectedFilters) {
      const routeConfig = {
        name: this.routeName,
        params: { ...this.$route.params },
        query: { ...this.$route.query },
      };
      this.pushFilterAnalytics(
        this.$route.path,
        'category',
        selectedFilters,
        this.allCategories
      );
      this.$emit('categoriesSelected', selectedFilters);
      routeConfig.query['sub-categories'] = selectedFilters;
      this.$router.push(routeConfig).catch((err) => {});
    },
  },
};
</script>
