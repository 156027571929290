<template>
  <v-container class="covidFilter pa-0 pt-4">
    <v-row no-gutters>
      <v-col cols="12">
        <span class="subtitle" v-text="$t('discovery.sort')" />
      </v-col>
      <v-col cols="12">
        <v-list dense flat class="pa-0">
          <v-list-item-group multiple>
            <label
              :for="option.query"
              v-for="(option, i) in items"
              :key="i"
              @click.capture.stop="toggleSelect(option)"
            >
              <v-list-item>
                <v-list-item-action>
                  <v-checkbox
                    :id="option.query"
                    v-model="option.toggle"
                    name="currentSelectedOptions"
                    color="primary"
                    @click="toggleSelect(option)"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="item-label">
                    {{ option.label }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </label>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BookingSearchMixins from '../../../mixins/BookingSearchMixins';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';

export default {
  name: 'CovidFilterMobile',
  mixins: [BookingSearchMixins, GoogleTagManagerMixins],

  props: {
    items: Array,
    preSelectedCovidFilter: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      hasSelectedItems: false,
      currentSelectedOptions: [],
    };
  },

  created() {
    this.setDefaultSelection();
  },

  methods: {
    toggleSelect(val) {
      if (
        this.currentSelectedOptions.includes(val.query) &&
        val.toggle === true
      ) {
        this.currentSelectedOptions = this.currentSelectedOptions.filter(
          (item) => item !== val.query
        );
      } else {
        this.currentSelectedOptions = [
          ...this.currentSelectedOptions,
          val.query,
        ];
      }
      this.currentSelectedOptions = [...new Set(this.currentSelectedOptions)];
      this.$emit('set-covid', this.currentSelectedOptions);
    },

    setDefaultSelection() {
      this.currentSelectedOptions = this.preSelectedCovidFilter || [];
      this.items.forEach((item) => {
        item.toggle = this.currentSelectedOptions.includes(item.query);
      });

      this.hasSelectedItems = !!this.currentSelectedOptions.length;
    },

    clearValue() {
      this.currentSelectedOptions = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.subtitle {
  display: block;
  padding-bottom: 0.5rem;
  font-weight: 600;
  font-size: 1rem;
}
.covidFilter {
  min-width: auto;

  & .v-list {
    padding: 0 !important;
  }
  & .v-list-item {
    min-height: 48px;
    padding: 0;
  }
  & .v-list-item__action {
    &:first-child {
      margin-right: 11px;
    }
    & .v-input--checkbox {
      position: absolute;
      top: 12px;
      & .v-icon {
        color: #9d9d9d;
      }
    }
  }
}

::v-deep .v-list-item__title.item-label {
  @include font-size(16, 24);
  color: #05090f;
}
</style>
