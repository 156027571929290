<template>
  <v-container class="pa-0">
    <v-row no-gutters>
      <v-col cols="12">
        <span class="subtitle" v-text="$t('discovery.date')" />
      </v-col>
      <v-col cols="12">
        <v-date-picker
          v-model="date"
          :allowed-dates="allowedDates"
          :picker-date.sync="pickerDate"
          color="primary"
          :elevation="0"
          no-title
          range
          show-adjacent-months
          class="date-picker elevation-0"
          @click:date="onDateClick"
          :locale="userPreferredLanguage"
          height="380"
          :width="$vuetify.breakpoint.mdAndDown ? '100%' : '352'"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LanguageMixins from '@/core/mixins/LanguageMixins';
import BookingSearchMixins from '../../../mixins/BookingSearchMixins';
import moment from 'moment';

export default {
  name: 'DateRangeMobile',
  mixins: [LanguageMixins, BookingSearchMixins],
  props: {
    preSelectedDateFilter: {
      type: Object,
      default: () => ({ min: '', max: '' }),
    },
  },

  data() {
    return {
      hasSelectedItems: false,
      date: '',
      sortedDates: [],
      pickerDate: null,
      selectedDates: {
        min: '',
        max: '',
      },
    };
  },

  created() {
    this.setDefaultSelection();
  },

  computed: {
    dateRange() {
      return [
        this.date[0],
        Boolean(this.date[1]) ? this.date[1] : this.date[0],
      ];
    },

    hasSelectedDates() {
      return !!this.sortedDates.length;
    },
  },

  methods: {
    formatDate(val) {
      return moment(new Date(val)).format('YYYY-MM-DD');
    },

    onDateClick(e) {
      this.sortedDates = [];
    },

    setDefaultSelection() {
      let startDate = this.preSelectedDateFilter.min || '';
      let endDate = this.preSelectedDateFilter.max || '';
      if (startDate || endDate) {
        this.sortedDates = [startDate, endDate];
        this.selectedDates.min = startDate;
        this.selectedDates.max = endDate;
        this.date = [this.formatDate(startDate), this.formatDate(endDate)];
      } else {
        this.selectedDates.min = '';
        this.selectedDates.max = '';
        this.sortedDates = [];
        this.date = [
          this.formatDate(this.getTodaysDate()),
          this.formatDate(this.getTodaysDate()),
        ];
      }
    },

    clearDefaultSelection() {
      this.date = '';
      this.pickerDate = moment(this.getTodaysDate()).format('YYYY-MM');
    },

    getTodaysDate() {
      return new Date(Date.now());
    },

    allowedDates(val) {
      const now = this.getTodaysDate();
      const yesterday = new Date(now.setDate(now.getDate()));
      return (
        new Date(val).setHours(0, 0, 0, 0) >= yesterday.setHours(0, 0, 0, 0)
      );
    },

    clearValue() {
      this.clearDefaultSelection();
    },
  },

  watch: {
    date: {
      deep: true,
      handler() {
        this.$emit('set-date', {
          min: this.dateRange[0],
          max: this.dateRange[1],
        });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.subtitle {
  display: block;
  padding-bottom: 1rem;
  font-weight: 600;
  font-size: 1rem;
}

::v-deep {
  .v-list-item__title.item-label {
    @include font-size(16, 24);
    color: #05090f;
  }
  & .sub-label {
    @include font-size(14, 20);
    color: var(--v-grey7-base);
    white-space: initial;
  }

  & .v-date-picker-table__current {
    border: none;
    background-color: #eeeeee;
  }

  & .v-date-picker-header__value {
    font-size: 16px;
  }

  & .v-date-picker-header {
    padding-bottom: 20px;
  }

  & .v-date-picker-table {
    padding: 0;
    & thead th {
      border-bottom: 1px solid #eeeeee;
      font-size: 14px;
    }
    & table {
      border-collapse: collapse;
    }

    & tbody {
      & td {
        padding: 4px 1px;
        & button {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
